<script lang="ts">
import { useUserDefaultRole } from '@nhost/vue';
import { useCompanyStore } from '@/store/company'
import { useClientStore } from '@/store/client'

export default {
  setup() {
    const companyStore = useCompanyStore();
    const clientStore = useClientStore();
    return {
      companyStore,
      clientStore
    }
  },
  data() {
    return {}
  },
  mounted() {
    const { value: role } = useUserDefaultRole()

    this.clientStore.clearSelectedClient()
    this.companyStore.clearCompany()

    if (role === 'investor') window.location.replace(`${import.meta.env.VITE_APP_CONTROLTOWER_COMPANIES_URL}/${this.companyStore.getCompany.id || ''}`);
    else if (role === 'user') window.location.replace(`${import.meta.env.VITE_APP_COCKPIT_URL}`);
  }
}
</script>

<template>
  <main></main>
</template>
