import { defineStore } from 'pinia'
import { ref } from 'vue'
import { gql } from '@apollo/client/core'
import { apolloClient } from '@/plugins/nhost'

export const STORE_NAME = 'client'

export interface ClientOrganization {
  organization: {
    name: string;
  }
}

export interface ClientSegment {
  pt_br_name: string;
  en_name: string;
}

export interface Client {
  general_client: Client[];
  id: string;
  name: string;
  document: string;
  is_active: boolean;
  last_data_imported_at: string;
  primary_color: string;
  short_logo_url: string;
  logo_url: string;
  segment: ClientSegment;
  metadata: {
    [key: string]: any;
  };
}

const GET_COMPANY_QUERY = gql`
  query getCompanyStore($where: general_client_bool_exp) {
    general_client (where: $where) {
      id
      name
      document
      last_data_imported_at
      primary_color
      short_logo_url
      logo_url
      segment {
        pt_br_name
        en_name
      }
      metadata
    }
  }
`;

export const useClientStore = defineStore(STORE_NAME, () => {
  const selectedClientId = ref<string | null>(null)
  const selectedClient = ref<Client | null>(null)

  async function setSelectedClientId(clientId: string) {
    if (!clientId) return

    const { data } = await apolloClient.query<Client>({
      query: GET_COMPANY_QUERY,
      variables: {
        where: {
          id: {
            _eq: clientId
          }
        }
      }
    })

    localStorage.setItem(STORE_NAME, JSON.stringify(data.general_client[0]))
  }

  async function getSelectedClient() {
    const clientLocalStorage = localStorage.getItem(STORE_NAME)

    try {
      selectedClient.value = clientLocalStorage ? JSON.parse(clientLocalStorage) : null
      return selectedClient.value
    } catch {
      return null;
    }

  }

  async function clearSelectedClient() {
    localStorage.removeItem(STORE_NAME)
  }

  return {
    selectedClientId,
    selectedClient,
    setSelectedClientId,
    getSelectedClient,
    clearSelectedClient
  }
})
