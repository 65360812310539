// Composables
import { createRouter, createWebHistory } from "vue-router";
import nhost from "@/plugins/nhost";
import SignIn from "@/views/auth/SignIn.vue";
import ForgotPassword from "@/views/auth/ForgotPassword.vue";
import SignUp from "@/views/auth/SignUp.vue";
import Logout from "@/views/auth/Logout.vue";
import ChangePassword from "@/views/auth/ChangePassword.vue";
import FirstAccess from "@/views/auth/FirstAccess.vue";
import { gql } from '@apollo/client/core'

const routes = [
  {
    path: "/",
    name: "root",
    component: () =>
      import(/* webpackChunkName: "public" */ "@/layouts/public/Default.vue"),
    children: [
      {
        path: "",
        name: "signin-redirect",
        redirect: {
          name: "signin",
        },
      },
      {
        path: "sign-in",
        name: "signin",
        component: SignIn,
      },
      {
        path: "sign-up",
        name: "signup",
        component: SignUp,
      },
      {
        path: "forgot-password",
        name: "forgotpassword",
        component: ForgotPassword,
      },
      {
        path: "change-password",
        name: "changepassword",
        component: ChangePassword,
      },
      {
        path: "first-access",
        name: "firstaccess",
        component: FirstAccess,
      },
    ],
  },
  {
    path: "/app",
    name: "app",
    component: () =>
      import(/* webpackChunkName: "private" */ "@/layouts/private/Default.vue"),
    meta: {
      auth: true,
    },
    children: [
      {
        path: "",
        name: "appHome",
        redirect: {
          name: "dashboard",
        },
      },
      {
        path: "cockpit",
        name: "cockpitMenu",
        children: [
          {
            path: "",
            name: "cockpitHome",
            component: () =>
              import(
                /* webpackChunkName: "cockpit" */ "@/views/cockpit/Home.vue"
              ),
          },
          {
            path: "financial",
            name: "cockpitMenuFinancial",
            component: () =>
              import(
                /* webpackChunkName: "cockpit-financial" */ "@/views/cockpit/Financial/Home.vue"
              ),
          },
          {
            path: "accounting",
            name: "cockpitMenuAccounting",
            component: () =>
              import(
                /* webpackChunkName: "cockpit-accounting" */ "@/views/cockpit/Accounting/Home.vue"
              ),
          },
          {
            path: "indicators",
            name: "cockpitMenuIndicators",
            component: () =>
              import(
                /* webpackChunkName: "cockpit-indicators" */ "@/views/cockpit/Indicators/Home.vue"
              ),
          }
        ],
      },
      {
        path: "company-area",
        name: "companyArea",
        component: () =>
          import(
            /* webpackChunkName: "company-area" */ "@/views/companyArea/Home.vue"
          ),
      },
      {
        path: "dashboard",
        name: "dashboard",
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "@/views/analytics/Dashboard.vue"
          ),
      },
      {
        path: "logout",
        name: "logout",
        component: Logout,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});


const INSERT_USER_EVENT = gql`
  mutation create($event_name: String, $extra_fields: jsonb) {
    insert_user_analysis_user_events_one(object: {event_name: $event_name, extra_fields: $extra_fields}) {
      event_name
    }
  }
`


router.beforeEach(async (to) => {
  const authenticated = await nhost.auth.isAuthenticatedAsync();
  if (!authenticated && to.meta.auth) {
    return "/sign-in";
  }

  try {
    nhost.graphql.request(INSERT_USER_EVENT, {
      event_name: to.fullPath,
      extra_fields: {
        "host": 'plataforma-dataroom-web'
      }
    })
  } catch {}

  return true;
});

export const menuItems = [
  {
    name: "dashboard",
    route: "/app/dashboard",
    icon: "FundOutlined",
    iconActive: "FundFilled",
    disabled: false,
  },
  {
    name: "cockpit",
    route: "/app/cockpit",
    icon: "DashboardOutlined",
    iconActive: "DashboardFilled",
    disabled: false,
  },
  {
    name: "company-area",
    route: "/app/company-area",
    icon: "ExportOutlined",
    iconActive: "ExportFilled",
    disabled: false,
  },
  {
    name: "logout",
    route: "/app/logout",
    icon: "LogoutOutlined",
    iconActive: "LogoutFilled",
    disabled: false,
  },
];

export default router;
