import { defineStore } from 'pinia'

export const STORE_NAME = 'company'

export const useCompanyStore = defineStore(STORE_NAME, {
    state: () => ({ company: { id: '' } }),
    getters: {
      getCompany: (): { id: string } => {
        const companyLocalStore = localStorage.getItem(STORE_NAME)
        try {
          return companyLocalStore ? JSON.parse(companyLocalStore).company : {}
        } catch {
          return { id: '' };
        }
      }
    },
    actions: {
      setCompany(company: { id: string }) {
        this.company = company;
        localStorage.setItem(STORE_NAME, JSON.stringify(this.$state))
      },
      clearCompany() {
        localStorage.removeItem(STORE_NAME)
      }
    },
  })
